<template>
  <b-container fluid>
    <h3 class="mt-3 mb-2">Contato</h3>
    <b-row>
      <b-col :cols="cols[0]">
        <b-form-group>
          <label for="email">Email</label>
          <b-input
            type="email"
            id="email"
            v-model="form.email"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="cols[1]">
        <b-form-group>
          <label for="telephone">Telefone</label>
          <b-input
            id="telephone"
            v-model="form.telephone"
            placeholder="Descrever"
            v-mask="'(##) ####-####'"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="cols[2]">
        <b-form-group>
          <label for="cellphone">Celular</label>
          <b-input
            id="cellphone"
            v-model="form.cellphone"
            placeholder="Descrever"
            v-mask="'(##) #####-####'"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="cols[3]" v-if="type === 'paciente'">
        <b-form-group>
          <label for="cellphone2">Celular 2</label>
          <b-input
            id="cellphone2"
            v-model="form.cellphone2"
            placeholder="Descrever"
            v-mask="'(##) #####-####'"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ContactData',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      validator: value => ['acompanhante', 'paciente'].includes(value)
    }
  },
  computed: {
    cols() {
      return this.type === 'acompanhante' ? [6, 3, 3] : [4, 3, 3, 2]
    }
  }
}
</script>
